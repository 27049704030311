iframe#agreement-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  border: none;
}

iframe#iframe-tutorial {
  width: 100%;
  height: 600px;
}

.overlay {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.modal-popup {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 20vh auto;
  width: 700px;
  height: 350px;
  transform-style: preserve-3d;
  z-index: 9999999999;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.7);
}
.modal-popup .header {
  height: 40px;
}
.modal-popup .wrapper {
  padding: 12px;
  height: 100%;
  box-sizing: border-box;
}
.modal-popup .button-group {
  height: inherit;
  width: 450px;
  display: inline-block;
  background-color: #424242;
  border-radius: 8px;
}
.modal-popup .button-group a {
  display: block;
  display: inline-block;
  width: 49%;
  height: inherit;
  line-height: 38px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  position: relative;
}
.modal-popup .button-group a:first-child {
  border-radius: 8px 0 0 8px;
}
.modal-popup .button-group a:first-child:before {
  content: "";
  background: url(../img/app/ios.svg) no-repeat;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 8px;
  left: 16px;
}
.modal-popup .button-group a:last-child {
  border-radius: 0 8px 8px 0;
  float: right;
}
.modal-popup .button-group a:last-child:before {
  content: "";
  background: url(../img/app/android.svg);
  position: absolute;
  background-size: contain;
  width: 20px;
  height: 20px;
  top: 8px;
  left: 16px;
}
.modal-popup .button-group.ios a:first-child {
  background-color: #ffc300;
  color: #424242;
  pointer-events: none;
}
.modal-popup .button-group.android a:last-child {
  background-color: rgb(166, 198, 79);
  pointer-events: none;
}
.modal-popup .logo {
  position: absolute;
  right: 0;
  top: 0;
  width: 193px;
  height: 62px;
  transform: scale(0.9);
  background: url(../img/app/icm_logo.png) no-repeat 0px 0px;
}
.modal-popup .content .instruction ul {
  counter-reset: instruction-counter;
  padding-top: 16px;
  width: 500px;
  display: none;
}
.modal-popup .content .instruction ul li {
  line-height: 40px;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
}
.modal-popup .content .instruction ul li span {
  display: block;
  color: #ffa100;
  line-height: 12px;
  padding-left: 34px;
}
.modal-popup .content .instruction ul li:before {
  content: counter(instruction-counter);
  counter-increment: instruction-counter;
  padding: 4px 9px;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  color: #4664b5;
  border-radius: 50%;
}
.modal-popup .qr {
  position: absolute;
  right: 0;
  top: 60px;
  width: 200px;
  height: 200px;
  background-size: 100% 100%;
}
.modal-popup .qr > span {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 23px;
  color: #fff;
  position: absolute;
  bottom: -43px;
}
.modal-popup .info {
  height: 40px;
  color: #fff;
  font-size: 15px;
  margin-top: 16px;
}
.modal-popup .info > span {
  display: block;
  width: 90%;
  float: right;
}
.modal-popup .info > span:first-child {
  width: 10%;
  float: left;
  color: #ffc300;
  padding-left: 8px;
  box-sizing: border-box;
}
.modal-popup .qr {
  position: absolute;
  right: 0;
  top: 60px;
  width: 200px;
  height: 200px;
  background-size: 100% 100%;
  transform: scale(0.8);
  box-sizing: border-box;
  background: #fff;
}

.qr img {
  width: 100%;
}

.barcodeURL {
  display: inline;
}